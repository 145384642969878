<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      :page_title="$route.params.subName.toLowerCase()"
      :item_btn_add="true"
      :btn_text="'addnew'"
      :item_no="item_No ? item_No : 0"
      @ShowAction="ShowAction"
    />

    <Article v-if="$route.params.subName =='Article'" ref="articleComponent" @itemsCount="getItem_No"/>
    <!-- Article  $t('article')-->
    <Sound v-if="$route.params.subName =='Sound'" ref="soundComponent" @itemsCount="getItem_No"/>
    <!-- Sound $t('sound')-->
    <Video v-if="$route.params.subName =='Video'" ref="videoComponent" @itemsCount="getItem_No"/>
    <!-- Video $t('video') -->
  </div>
</template>

<script>
import Article from "./_article/article-index.vue";
import Sound from "./_sound/index.vue";
import Video from "./_video/index.vue";
export default {
  components: {
    Article,
    Sound,
    Video
  },
  data() {    
    return {
      item_No: 0,
      breadcrumbs: [
        {
          // image: 'house.png',
          sidebar_tilte: "home"
        },
        {
          text: "library",
          href: "/home/library"
        },
        {
          text: this.$route.query.topic_name,//this.$route.params.name
          href: "/home/library/data"
        },
        {
          text: this.$route.params.subName.toLowerCase()
        }
      ]
    };
  },
  methods: {
    ShowAction() {
      if (this.$route.params.subName == "Article") {
        this.$refs.articleComponent.checkArticleAdd();
      } else if (this.$route.params.subName == "Sound") {
        this.$refs.soundComponent.checkSoundAdd();
      } else if (this.$route.params.subName == "Video") {
        this.$refs.videoComponent.checkVideoAdd();
      }
    },
    getItem_No(value) {
      this.item_No = value;
   }
  }
};
</script>