<template>
  <div>
    <v-card class="mx-3 my-5" style="border-radius:16px;">
      <v-container>
        <!-- fluid -->
        <v-row justify="space-between" class="ma-1">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              clearable
              hide-details
              style="border-radius:8px"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-1">
          <v-col>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              hide-default-footer
              :search="search"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :items="items"
            >
              <template v-slot:item.sort="{ item }">
                <div class="d-flex">
                  <v-menu open-on-hover offset-y min-width="55px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="55"
                        height="35"
                        color="#47484b"
                        class="text-capitalize mt-1 sort_Btn"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.sort }}
                        <v-icon style="margin-left: 0px;margin-right: -0.6rem;">mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      :style="items.length == 1? {height: '69px'} :
                items.length == 2 ? {height: '103px'} : items.length == 3 ? {height: '136px'} : items.length == 4 ? {height: '170px'} : {height: '205px'}"
                      class="overflow-y-auto"
                    >
                      <v-list-item
                        v-for="(temp,index) in sortNumberItems"
                        :key="index"
                        @click="item.sort = temp.value, changeSubmitSortNo(item)"
                      >
                        <v-list-item-title class="custom_list_item">{{ temp.value }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <template v-slot:item.actions="{item}">
                <v-btn
                  text
                  color="#4FB14E"
                  class="text-capitalize"
                  style="font-size: 16px"
                  @click="$store.commit('saveEditSound',item),editSound()"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                  {{ $t("edit") }}
                </v-btn>
                <v-btn
                  @click="checkSoundDelete(item)"
                  text
                  color="#FF6060"
                  class="text-capitalize"
                  style="font-size: 16px"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                  {{ $t("delete") }}
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- New Pagination Ui -->
        <v-row justify="space-between" class="mx-6 my-4">
          <v-row>
            <v-col cols="auto" class="mr-auto">
              <v-pagination circle v-model="page" class="pagination" :length="pageCount"></v-pagination>
            </v-col>
            <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
              <p class="mt-3 pr-4">{{ $t("Show")}}</p>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="70"
                    v-bind="attrs"
                    v-on="on"
                    style="border: 1px solid #C0C0C0; opacity: 1"
                    class="text-capitalize mt-1"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="itemsPerPage = item.title"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-card>
    <SuccessDialog
      :show="successDialog"
      :title="$t('success')"
      :text="message"
      @close="(successDialog = false), getSound()"
    />
    <DeleteConfirmDialog
      :show="deleteDialog"
      :text="deleteMessage"
      @close="deleteDialog = false"
      @confirm="confirmDelete"
    />
    <Add
      :show="addDialog"
      :ActiveNumberOfFilesCount="ActiveNumberOfFilesCount_"
      :full_name="fullName_"
      @close="addDialog = false"
      @confirm="
        (addDialog = false),
          (successDialog = true),
          (message = $t('addsoundsuccessful'))
      "
    />
    <Edit
      :show="editDialog"
      :ActiveNumberOfFilesCount="ActiveNumberOfFilesCount_"
      @close="
        (editDialog = false),$store.commit('removeFirstTimeEditDataForArticle')
      "
      @confirm="(editDialog = false), (successDialog = true),message=$t('updatesoundsuccessful')"
    />
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="(permissionDialog = false)"
    />
  </div>
</template>

<script>
import Add from "./addsound.vue";
import Edit from "./editsound.vue";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";
export default {
  components: {
    Add,
    Edit
  },
  data() {
    return {
      fullName_: "",
      sortNumberItems: [],
      permissionDialog: false,
      permissionMessage: "",
      message: "",
      search: "",
      items: [],
      deleteDialog: false,
      addDialog: false,
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
      deleteData: {},
      editDialog: false,
      successDialog: false,
      editData: {},
      ActiveNumberOfFilesCount_: 0
    };
  },

  computed: {
    ...mapGetters(["editLibraryData"]),
    headers() {
      return [
        {
          text: "#",
          align: "left",
          value: "no",
          width: "63px"
        },
        {
          text: this.$t("title"),
          align: "left",
          value: "lessonName",
          width: "200px"
        },
        {
          text: this.$t("description"),
          align: "left",
          value: "wordDescription",
          width: "180px"
        },
        {
          text: this.$t("startdate"),
          align: "left",
          value: "startContentDate1",
          width: "150px"
        },
        {
          text: this.$t("enddate"),
          align: "left",
          value: "endContentDate1",
          width: "150px"
        },
        {
          text: this.$t("createdateM"),
          align: "left",
          value: "create_Date",
          width: "160px"
        },
        {
          text: this.$t("createdby"),
          align: "left",
          value: "createBy",
          width: "160px"
        },
        {
          text: this.$t("sort"),
          align: "left",
          value: "sort",
          width: "100px"
        },
        {
          text: this.$t("Manage"),
          align: "center",
          value: "actions",
          width: "250px"
        }
      ];
    },
    deleteMessage() {
      return `Are you sure you want to delete "${this.deleteData.lessonName}" ?`;
    }
  },

  mounted() {
    this.getSound();
    this.getPricingData();
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.fullName_ = auth.fullName;
    }
  },
  methods: {
    async changeSubmitSortNo(v) {
      const data = {
        id: v.id,
        sort: v.sort == "" ? null : v.sort,
        separatePage: "Sound"
      };
      await this.$axios.post(`${this.web_url}Contents/UpdateSort`, data);
      this.getSound();
    },
    async getPricingData() {
      const res = await this.$axios.get(
        `${this.web_urlV4}Package/GetPackageOfCompanyCustomer?CompanyId=` +
          localStorage.getItem("companyID")
      );
      this.PricingData = res.data.data;
    },
    async getCountForEmployee() {
      const res = await this.$axios.get(
        `${this.web_url}Company/GetCompanyActiveUserMP3MP4Count?id=` +
          localStorage.getItem("companyID")
      );
      this.ActiveNumberOfFilesCount_ = res.data.data[0].numberOfFiles;
    },
    async checkSoundAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("library");
      if (checkPermission) {
        if (this.ActiveNumberOfFilesCount_ < this.PricingData.numberOfFilesId) {
          self.addDialog = true;
        } else {
          alert(
            "Unable to add new ones because the number of files has reached the limit. Please contact Seedkm administrator to enhance usage!"
          );
        }
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add Sound.";
      }
    },
    async checkSoundDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("library");
      if (checkPermission) {
        self.deleteDialog = true;
        self.deleteData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete Sound.";
      }
    },
    async editSound() {
      let self = this;
      let checkPermission = self.check_Permissions("library");
      if (checkPermission) {
        self.editDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to edit Sound.";
      }
    },
    async confirmDelete() {
      const res = await this.$axios.post(
        `${this.web_url}Video/RemoveVideoContent`,
        {
          id: this.deleteData.id
        }
      );
      if (res.data.status == 0) {
        this.deleteDialog = false;
        this.successDialog = true;
        this.message = this.$t("deletesound");
      }
    },
    async getSound() {
      let self = this;

      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.post(
          `${self.web_url}Video/GetVideoContentByTopicIDCustomerWeb`,
          {
            id: parseInt(localStorage.getItem("libraryId")),
            companyId: localStorage.getItem("companyID")
            // page: 0,
            // per_page: 0
          }
        );
        if (res.data.data == null) {
          self.loading = false;
          return;
        }

        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          create_Date: moment(res.data.data[i].createDate)
            .local()
            .format("DD/MM/YYYY"),
          createDate1: moment(res.data.data[i].createDate)
            .local()
            .format("DD MMM YYYY"),
          updateDate1: moment(res.data.data[i].updateDate)
            .local()
            .format("DD MMM YYYY"),
          startContentDate1: moment(res.data.data[i].startContentDate)
            .local()
            .format("DD/MM/YYYY"),
          endContentDate1: moment(res.data.data[i].endContentDate)
            .local()
            .format("DD/MM/YYYY")
        }));
        self.getCountForEmployee();

        let itemCount = self.items ? self.items.length : 0;
        this.$emit('itemsCount', itemCount);

        let temp_null = [{ value: "" }];
        let temp_num = self.items.map((v,i) => {
          return { value: i + 1 };
        });
        self.sortNumberItems = temp_null.concat(temp_num);
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see Sound.";
      }
      self.loading = false;
    }
  }
};
</script>
<style scoped>
::v-deep tr:nth-child(even) {
  background: unset;
}
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-text-field__slot {
  font-size: 20px !important;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}

/* For Sort menu. */
::v-deep .sort_Btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 50px;
  font-size: 18px;
  padding: 0 0;
  border: 1px solid #c0c0c0; /**#47484b */
  border-radius: 8px;
  opacity: 1 !important;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-track {
  width: 6px !important;
  background: #e6e6e6;
}
/* Handle */
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 2px #e6e6e6;
  border-radius: 7px;
}
::v-deep .v-list.overflow-y-auto.v-sheet.theme--light {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
::v-deep .v-list-item {
  min-height: 32px;
  border-top: 2px solid #707070;
}
::v-deep .v-list-item:hover {
  background-color: #707070 !important; /*#707070 */
}
::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: #ffffff !important;
}
/* end sort menu. */
</style>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
    margin-top: 1.5rem !important;
  }
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
</style>