<template>
  <div>
    <v-dialog v-model="dialog" width="1229" scrollable persistent>
      <v-card>
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("addarticle") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="cancelAdd()"
            color="#424242"
            style="border-radius: 8px !important"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12">
                    <p style="color: #424242; font-size: 16px">
                      {{ $t("createdateM") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ this.createdate }} {{ $t("By") }} {{ this.full_name }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <label for="title" class="font-style">
                      {{ $t("articletitle") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      :rules="titleRules"
                      outlined
                      dense
                      hide-details="auto"
                      id="title"
                      v-model="article.title"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      id="description"
                      v-model="article.description"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    class="loading-circle"
                    color="green"
                  ></v-progress-circular>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label class="font-style" for="startTime">
                      {{ $t("startdate") }}
                    </label>
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="startDate"
                          append-icon="mdi-calendar"
                          v-model="article.startDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedStartDates"
                        color="secondary"
                        v-model="article.startDate"
                        @input="startdateDis()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label class="font-style" for="endDate">
                      {{ $t("enddate") }}
                    </label>
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="endDate"
                          append-icon="mdi-calendar"
                          v-model="article.endDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedEndDates"
                        color="secondary"
                        v-model="article.endDate"
                        @input="endDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row style="margin-top: 2.7rem">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style mb-1"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div>
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 4px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :src="article.file"
                          @click="$refs.file.click()"
                          @mouseover="
                            article.file
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            article.file
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="article.file"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="article.file = null"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>

                        <v-img
                          v-if="!article.file"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!article.file"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!article.file"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          font-size: 16px;
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          width: 165px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p class="mt-3" style="color: #777777; font-size: 12px">
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col style="padding-bottom: 0">
                <label for="wordContent" class="font-style">
                  {{ $t("wordcontent") }}
                  <span style="color: red">*</span>
                </label>
                <v-textarea
                  :rules="wordContentRules"
                  outlined
                  no-resize
                  id="wordContent"
                  v-model="article.wordContent"
                  class="rounded-lg"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" style="padding-top: 0">
                <label for="link" class="font-style">{{ $t("link") }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  id="link"
                  @input="checkLinkType"
                  v-model="article.link"
                  class="rounded-lg"
                ></v-text-field>
                <span v-if="notLink" style="color: red; font-size: 13px">{{
                  $t("invalidUrlLink")
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
                lg="2"
                class="mt-1 pr-0"
                style="padding-bottom: 0"
              >
                <v-row class="pl-4">
                  <input
                    type="file"
                    accept="application/pdf"
                    class="d-none"
                    ref="pdffile"
                    @change="getpdfFile($event)"
                  />
                  <v-btn
                    color="#A6CC39"
                    style="
                      font-size: 16px;
                      float: left;
                      border-radius: 8px;
                      height: 40px;
                    "
                    class="text-capitalize white--text btn_hover_effect"
                    :disabled="article.PDFButtonDisable"
                    @click="($refs.pdffile.value = null), $refs.pdffile.click()"
                  >
                    <v-icon class="mr-1">mdi-folder-search-outline</v-icon>
                    {{ $t("Browse File") }}
                  </v-btn>

                  <span class="mt-1" style="color: #777777; font-size: 12px">{{
                    $t("RecommendFormatPDF")
                  }}</span>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="4"
                class="mt-1 pl-0"
                style="padding-bottom: 0"
              >
                <label
                  for="browsefiles"
                  v-if="article.pdffilename != ''"
                  class="font-style"
                  >{{ article.pdffilename }}</label
                >
                <v-icon
                  color="#FF6060"
                  @click="DeletePDFfile"
                  v-if="article.PDFButtonDisable"
                  style="
                    cursor: pointer;
                    border: 1px;
                    margin-left: 5px;
                    margin-bottom: 3px;
                  "
                  small
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  hide-details
                  color="#A6CC39"
                  v-model="article.allview"
                  :label="$t('allusercanview')"
                ></v-checkbox>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" md="3" class="pt-0">
                <v-btn
                  color="#A6CC39"
                  style="
                    font-size: 16px;
                    margin-top: 13px;
                    float: right;
                    border-radius: 8px;
                  "
                  class="text-capitalize white--text btn_hover_effect"
                  @click="AddParticipant"
                  >{{ $t("requesteduser") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="selectedList.length != 0">
                <v-data-table
                  :items="selectedList"
                  class="article-table"
                  :loading="userLoading"
                  loading-text="Loading... Please wait"
                  :headers="selectedListUserHeader"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :page.sync="page"
                >
                  <template v-slot:item.request="{ item }">
                    <v-row justify="center">
                      <v-checkbox
                        style="border-radius: 8px"
                        color="#A6CC39"
                        v-model="item.request"
                      ></v-checkbox>
                    </v-row>
                  </template>
                  <template v-slot:item.perDay="{ item }">
                    <div v-if="item.request">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            v-model="item.selectedDate"
                            prepend-inner-icon="mdi-calendar-blank-outline"
                            :rules="[perDayRules.required]"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.perDay"
                          :allowed-dates="allowedPerday"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#A6CC39"
                            class="font-style"
                            @click="item.menu = false"
                            >{{ $t("cancel") }}</v-btn
                          >
                          <v-btn
                            text
                            class="font-style"
                            color="#A6CC39"
                            @click="
                              $refs.menu.save(item.perDay),
                                (item.menu = false),
                                (item.selectedDate = checkStatus(item.perDay))
                            "
                            >{{ $t("ok") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:item.index="{ item }">
                    <v-btn
                      color="#FF6060"
                      style="font-size: 16px; width: 100px"
                      text
                      class="text-capitalize"
                      @click="DeletefromUser(item)"
                    >
                      <v-icon left>mdi-delete-outline</v-icon>
                      {{ $t("remove") }}
                    </v-btn>
                  </template>
                  <template v-slot:no-data>
                    <p style="font-size: 16px">{{ $t("nodata") }}</p>
                  </template>
                </v-data-table>
                <v-row justify="end">
                  <div class="ma-5 d-flex">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-card class="customcardnoti">
                <v-card-subtitle
                  style="color: #424242; font-size: 18px; font-weight: bold"
                >
                  {{ $t("sendnotifications") }}
                </v-card-subtitle>
              </v-card>
            </v-row>
            <v-row class="ml-10" rows="12" sm="12" md="12" lg="12">
              <v-col>
                <v-checkbox
                  :label="$t('inbox')"
                  color="#a6cc39"
                  v-model="inbox_flag"
                  hide-details
                ></v-checkbox
              ></v-col>
              <v-col
                ><v-checkbox
                  color="#a6cc39"
                  :label="$t('pushnotification')"
                  v-model="pushNoti_flag"
                  hide-details
                ></v-checkbox
              ></v-col>
              <v-col>
                <v-checkbox
                  color="#a6cc39"
                  :label="$t('email')"
                  v-model="email_flag"
                  hide-details
                ></v-checkbox
              ></v-col>
              <v-col>
                <v-checkbox
                  color="#a6cc39"
                  :label="$t('line')"
                  v-model="line_flag"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            @click="cancelAdd()"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="submit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
      scrollable
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="CropImage()"
            width="100"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="article.file"
        ></v-img>
      </v-card>
    </v-dialog>

    <!-- Participant list -->
    <v-dialog width="500" v-model="listDialog" scrollable persistent>
      <v-card>
        <v-card-title>
          <v-tabs hide-slider v-model="tabs" class="pt-3">
            <v-tab
              href="#tab-1"
              style="
                width: 165px;
                border-radius: 10px 0px 0px 10px;
                border: 1px solid #a6cc39;
                color: #424242;
              "
              class="btnfont-style text-capitalize"
              >{{ $t("user") }}</v-tab
            >
            <v-tab
              href="#tab-2"
              style="
                width: 165px;
                border-radius: 0px 10px 10px 0px;
                border: 1px solid #a6cc39;
                color: #424242;
              "
              class="btnfont-style text-capitalize"
              >{{ $t("group") }}</v-tab
            >
          </v-tabs>
        </v-card-title>
        <v-card-text style="height: 500px">
          <v-tabs-items v-model="tabs" class="mt-5">
            <v-tab-item value="tab-1">
              <v-data-table
                :items="users"
                @toggle-select-all="SelectAllUser"
                item-key="userID"
                :loading="userLoading"
                loading-text="Loading... Please wait"
                v-model="userSelected"
                show-select
                :headers="userListHeader"
                hide-default-footer
                @page-count="userpageCount = $event"
                :page.sync="userpage"
              ></v-data-table>
              <v-row justify="end">
                <div class="ma-5 d-flex">
                  <v-pagination
                    circle
                    v-model="userpage"
                    class="pagination"
                    :length="userpageCount"
                  ></v-pagination>
                </div>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                @toggle-select-all="SelectAllDept"
                :items="departments"
                item-key="groupID"
                :loading="departmentLoading"
                loading-text="Loading... Please wait"
                v-model="departmentSelected"
                show-select
                :headers="departmentListHeader"
                hide-default-footer
                @page-count="deptpageCount = $event"
                :page.sync="deptpage"
              ></v-data-table>
              <v-row justify="end">
                <div class="ma-5 d-flex">
                  <v-pagination
                    circle
                    v-model="deptpage"
                    class="pagination"
                    :length="deptpageCount"
                  ></v-pagination>
                </div>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="
              (listDialog = false),
                (userSelected = []),
                ((departmentSelected = []), (this.tabs = 'tab-1'))
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="savePermissionList()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
import * as moment from "moment/moment";
export default {
  components: {
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    full_name: String,
  },
  watch: {
    userSelected(val) {
      if (val.length != 0) {
        this.departmentSelected = [];
        this.isUser = true;
      }
    },
    departmentSelected(val) {
      if (val.length != 0) {
        this.userSelected = [];
        this.isUser = false;
      }
    },
    "article.file": function () {
      this.fileError = false;
    },
  },
  computed: {
    ...mapGetters(["editLibraryData"]),
    timesRules() {
      return [(v) => !!v || ""];
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    userListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
        },
      ];
    },
    departmentListHeader() {
      return [
        {
          text: this.$t("Name"),
          align: "left",
          value: "groupName",
        },
      ];
    },
    selectedListUserHeader() {
      return [
        {
          text: "#",
          align: "left",
          value: "no",
          width: "50px",
          sortable: false,
        },
        {
          text: this.$t("Name"),
          align: "left",
          value: "fullName",
        },
        // {
        //   text: this.$t("viewpermission"),
        //   align: "center",
        //   value: "viewPermission",
        // },
        {
          text: this.$t("request"),
          align: "center",
          value: "request",
          width: "190px",
        },
        //{
        //  text: this.$t("repeat"),
        //   align: "center",
        //  value: "times",
        // },
        {
          text: this.$t("expiredate"),
          align: "center",
          value: "perDay",
          width: "250px",
        },
        {
          text: this.$t("action"),
          align: "center",
          value: "index",
          width: "190px",
        },
      ];
    },
    selectedListDepartmentHeader() {
      return [
        {
          text: this.$t("name"),
          align: "center",
          value: "groupName",
        },
        // {
        //   text: this.$t("viewpermission"),
        //   align: "center",
        //   value: "viewPermission",
        // },
        {
          text: this.$t("assign"),
          align: "center",
          value: "request",
        },
        // {
        //   text: this.$t("repeat"),
        //   align: "center",
        //   value: "times",
        // },
        {
          text: this.$t("expiredate"),
          align: "center",
          value: "perDay",
        },
        {
          text: this.$t("action"),
          align: "center",
          value: "index",
        },
      ];
    },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    wordContentRules() {
      return [(v) => !!v || "Word Content is required"];
    },
    isLink() {
      const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;
      return urlRegex.test(this.article.link);
      // Regular expression to check if the input is a valid URL
      // const urlRegex = /^(ftp|http(s)?:\/\/)?(www\.)?[^ "]+$/;
      // const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;

      // Return true if the input matches the URL pattern, false otherwise
      // return this.article.link.length != 0
      //   ? urlRegex.test(this.article.link)
      //   : true;
    },
  },
  data: () => ({
    inbox_flag: false,
    pushNoti_flag: false,
    email_flag: false,
    line_flag: false,
    notLink: false,
    previewimg: false,
    createdate: moment(new Date().toISOString().substr(0, 10))
      .local()
      .format("DD MMM YYYY"),
    showEditImgTool: false,
    perDayRules: {
      // required: v => v.length > 11 || '',
      required: (v) => v.split("-").length > 2 || "",
    },
    valid: true,
    loading: false,
    dialogCrop: false,
    page: 1,
    pageCount: 0,
    userpage: 1,
    userpageCount: 0,
    deptpage: 1,
    deptpageCount: 0,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    fileError: false,
    article: {
      title: "",
      description: "",
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      wordContent: "",
      file: null,
      fileName: "",
      fileUrl: "",
      allview: true,
      link: "",
      pdffilename: "",
      PDFButtonDisable: false,
      PDFUrl: "",
    },
    isUser: false,
    startDateMenu: false,
    endDateMenu: false,
    listDialog: false,
    userSelected: [],
    departmentSelected: [],
    tabs: "tab-1",
    users: [],
    departments: [],
    userLoading: true,
    departmentLoading: true,
    selectedList: [],
    perList: [],
    userdataList: [],
    departmentdataList: [],
  }),
  mounted() {
    this.getUserData();
    this.getDepartment();
  },
  methods: {
    checkLinkType() {
      if (this.isLink || this.article.link === "") {
        this.notLink = false;
      } else {
        this.notLink = true;
      }
    },
    startdateDis() {
      let that = this;
      that.startDateMenu = false;
      if (that.article.startDate > that.article.endDate) {
        that.article.endDate = that.article.startDate;
      }
    },

    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }
      this.article.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        // f.target.result contains the base64 encoding of the image
        // let src = f.target.result;
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },

    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.article.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    allowedStartDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates(val) {
      return val >= this.article.startDate;
    },
    allowedPerday(val) {
      return val <= this.article.endDate && val >= this.article.startDate;
    },
    checkStatus(item) {
      if (item) {
        return item;
      } else {
        return "Please Select"; //Date Picker
      }
    },
    async getpdfFile(e) {
      let that = this;
      let file = e.target.files[0];
      if (!/\.pdf$/.test(e.target.value)) {
        alert("Choose PDF files only, please re-select!");
        return false;
      } else {
        that.article.pdffilename = file.name;
        that.article.PDFUrl = e;
        that.article.PDFButtonDisable = true;
      }
    },
    DeletePDFfile() {
      let that = this;
      that.article.pdffilename = "";
      that.article.PDFUrl = "";
      that.article.PDFButtonDisable = false;
    },
    DeletefromUser(i) {
      this.selectedList.splice(
        this.selectedList.findIndex((item) => item.index === i.index),
        1
      );
      this.selectedList = this.selectedList.map((v, j) => ({
        ...v,
        no: j + 1,
      }));
      if (this.perList.length != 0) {
        this.perList.splice(
          this.perList.findIndex((item) => item.index === i.index),
          1
        );
        this.perList = this.perList.map((v, k) => ({
          ...v,
          no: k + 1,
        }));
      }
    },
    async savePermissionList() {
      this.listDialog = false;
      let user = [];
      let department = [];
      if (this.isUser) {
        for (let i = 0; i < this.userSelected.length; i++) {
          const filteruserdata = this.userdataList.find(
            (c) => c.employeeID == this.userSelected[i].userID
          );
          if (filteruserdata == undefined) {
            user.push({
              employeeID: this.userSelected[i].userID,
              fullName: this.userSelected[i].fullName,
              isCheck: true,
              isUser: true,
              perDay: this.userSelected[i].perDay,
              permission: false,
              request: false,
              selectedDate: "Please Select", //Date Picker
              times: 1,
              userPermissionID: this.userSelected[i].userID,
              index: this.userSelected[i].index,
              no: i + 1,
            });
          } else {
            user.push({
              employeeID: filteruserdata.employeeID,
              fullName: filteruserdata.fullName,
              isCheck: filteruserdata.isCheck,
              isUser: filteruserdata.isUser,
              perDay: filteruserdata.perDay,
              permission: filteruserdata.permission,
              request: filteruserdata.request,
              selectedDate: filteruserdata.selectedDate,
              times: filteruserdata.times,
              userPermissionID: filteruserdata.userPermissionID,
              index: filteruserdata.index,
              no: i + 1,
            });
          }
        }
      } else {
        for (let i = 0; i < this.departmentSelected.length; i++) {
          const filterdepartdata = this.departmentdataList.find(
            (c) => c.employeeID == this.departmentSelected[i].groupID
          );
          if (filterdepartdata == undefined) {
            department.push({
              employeeID: this.departmentSelected[i].groupID,
              fullName: this.departmentSelected[i].groupName,
              isCheck: true,
              isUser: false,
              perDay: this.departmentSelected[i].perDay,
              permission: false,
              request: false,
              selectedDate: "Please Select", //Date Picker
              times: 1,
              userPermissionID: this.departmentSelected[i].groupID,
              index: this.departmentSelected[i].index,
              no: i + 1,
            });
          } else {
            department.push({
              employeeID: filterdepartdata.employeeID,
              fullName: filterdepartdata.fullName,
              isCheck: filterdepartdata.isCheck,
              isUser: filterdepartdata.isUser,
              perDay: filterdepartdata.perDay,
              permission: filterdepartdata.permission,
              request: filterdepartdata.request,
              selectedDate: filterdepartdata.selectedDate,
              times: filterdepartdata.times,
              userPermissionID: filterdepartdata.userPermissionID,
              index: filterdepartdata.index,
              no: i + 1,
            });
          }
        }
      }
      this.selectedList = this.isUser ? user : department;
      this.userdataList = user;
      this.departmentdataList = department;
      this.perList = this.isUser ? this.userSelected : this.departmentSelected;
    },
    SelectAllUser() {
      if (this.userSelected == undefined) {
        this.userSelected = this.users;
      } else if (this.userSelected.length == 0) {
        this.userSelected = this.users;
      } else {
        this.userSelected = [];
      }
    },
    SelectAllDept() {
      if (this.departmentSelected == undefined) {
        this.departmentSelected = this.departments;
      } else if (this.departmentSelected.length == 0) {
        this.departmentSelected = this.departments;
      } else {
        this.departmentSelected = [];
      }
    },
    async getUserData() {
      let self = this;
      self.userLoading = true;
      await self.$axios
        .get(
          `${self.web_urlV6}EmployeeEXT/GetEmployeeExtAllV4?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          self.users = res.data.data.map((v, i) => ({
            ...v,
            permission: false,
            request: false,
            repeat: 0,
            menu: false,
            date: [],
            dateStatus: "Please Select", //Date Picker
            index: i,
          }));
        })
        .catch(function (err) {
          alert(err);
        });

      self.userLoading = false;
    },

    async getDepartment() {
      let self = this;
      self.departmentLoading = true;
      await self.$axios
        .get(
          `${self.web_url}Group/GetGroupALL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          self.departments = res.data.data.map((v, i) => ({
            ...v,
            permission: false,
            request: false,
            repeat: 0,
            menu: false,
            date: [],
            dateStatus: "Please Select", //Date Picker
            index: i,
          }));
        })
        .catch(function (err) {
          alert(err);
        });

      self.departmentLoading = false;
    },

    getFile(e) {
      this.article.fileUrl = e;
      this.article.file = e;
      this.showEditImgTool = false;
    },

    imageTobase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.article.file = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    cancelAdd() {
      this.$emit("close");
      this.$refs.form.resetValidation();
      this.$refs.file.value = null;
      this.fileError = false;
      this.article = {
        title: "",
        description: "",
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        wordContent: "",
        file: null,
        fileName: "",
        fileUrl: "",
        allview: true,
        link: "",
        pdffilename: "",
        PDFUrl: "",
        PDFButtionDisable: false,
      };
      this.departmentSelected = [];
      this.userSelected = [];
      this.selectedList = [];
      this.perList = [];
      this.userdataList = [];
      this.departmentdataList = [];
      this.tabs = "tab-1";
      this.inbox_flag = false;
      this.pushNoti_flag = false;
      this.email_flag = false;
      this.line_flag = false;
    },
    AddParticipant() {
      this.listDialog = true;
      if (this.isUser) {
        this.userSelected = this.perList;
      } else {
        this.departmentSelected = this.perList;
      }
    },
    async submit() {
      if (this.$refs.form.validate() && this.notLink === false) {
        if (this.article.file) {
          this.loading = true;
          let permissionList = [];
          for (const element of this.selectedList) {
            permissionList.push({
              articleID: 0,
              userPermissionID: element.userPermissionID,
              //permission: this.selectedList[i].permission,
              request: element.request,
              isUser: element.isUser,
              isComplete: false,
              times: element.times,
              perDay: element.perDay,
            });
          }
          if (this.article.PDFUrl != "") {
            let formData = new FormData();
            formData.append("file", this.article.PDFUrl.target.files[0]);
            const res = await this.$axios.post(
              `${this.web_url}File/UploadPDFFile`,
              formData
            );
            this.article.PDFUrl = res.data.data;
          }
          const base64Res = await this.$axios.post(
            `${this.web_url}File/UploadFileStringBase`,
            {
              base64: this.article.file,
              fileName: this.article.fileName,
            }
          );

          const userdata = JSON.parse(
            localStorage.getItem("vivek_authenticated_user_data")
          );
          const memberid = userdata.memberId;
          const data = {
            pdfFile: "",
            wordContentFile: "",
            wordContentText: this.article.wordContent,
            contentName: this.article.title,
            wordDescription: this.article.description,
            startContentDate: this.article.startDate,
            endContentDate: this.article.endDate,
            topicID: parseInt(this.editLibraryData.id),
            picturebackground: base64Res.data,
            isUserGroup: this.isUser ? 2 : 1,
            attachFileName: this.article.fileName,
            articlePermissionData: permissionList,
            bgBase64: this.article.file,
            allView: this.article.allview == null ? true : this.article.allview,
            link: this.article.link,
            createBy: memberid,
            pdfUrl: this.article.PDFUrl,
            pdFfilename: this.article.pdffilename,
            inboxFlag: this.inbox_flag,
            emailFlag: this.email_flag,
            pushNotiFlag: this.pushNoti_flag,
            lineFlag: this.line_flag,
          };
          const res1 = await this.$axios.post(
            `${this.web_url}Contents/AddWordContent`,
            data
          );
          if (res1.data.status == 0) {
            this.article = {
              title: "",
              description: "",
              startDate: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10),
              endDate: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10),
              wordContent: "",
              file: null,
              fileName: "",
              fileUrl: "",
              allview: true,
              link: "",
              pdffilename: "",
              PDFUrl: "",
              PDFButtionDisable: false,
            };
            this.inbox_flag = false;
            this.pushNoti_flag = false;
            this.email_flag = false;
            this.line_flag = false;
            this.$refs.file.value = null;
            this.$emit("confirm");
            this.$refs.form.resetValidation();
            this.fileError = false;
            this.selectedList = [];
            this.userdataList = [];
            this.departmentdataList = [];
            this.perList = [];
          }
        } else {
          this.fileError = true;
          alert("Please attach a picture!");
        }
      } else {
        alert("Incomplete information Please enter again!");
      }

      this.loading = false;
    },
  },
};
</script>
<style scoped>
.customcardnoti {
  width: 98%;
  margin-left: 10px;
  background-color: #e0e0e0;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .v-dialog {
  border-radius: 18px !important; /**35px */
}
::v-deep .preview_class.v-dialog {
  border-radius: 8px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
.btnfont-style {
  font-size: 20px;
}
.font-style {
  font-size: 16px;
  color: #424242;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .article-table tr:nth-child(even) {
  background: unset;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .v-tab--active {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  /* opacity: 1; */
}
.v-application .primary--text {
  color: #aecb53 !important;
  caret-color: #aecb53 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>

<style scoped>
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
</style>
